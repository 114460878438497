.bg-image[data-v-513342b2] {
  display: flex;
  position: relative;
}
.bg-image .card-img[data-v-513342b2] {
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 12px;
}
.bg-image .text-amount[data-v-513342b2] {
  padding: 2px 15px 2px 30px;
  border-bottom-right-radius: 12px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  position: absolute;
  right: 0;
  bottom: 0;
}
.bg-color[data-v-513342b2] {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 12px;
  padding: 20px 15px;
}
.bg-color .text-amount-pos[data-v-513342b2] {
  position: absolute;
  right: 15px;
  bottom: 2px;
}
.big-style[data-v-513342b2] {
  width: calc(100%);
  height: 216px;
}
.small-style[data-v-513342b2] {
  width: 240px;
  height: 150px;
}
.send-btn[data-v-513342b2] {
  position: absolute;
  left: 15px;
  bottom: 8px;
  border-radius: 6px;
  font-size: 14px !important;
}
