.page-nav-view[data-v-28088d38] {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  height: 44px;
  background-color: #EFEFEF;
  position: relative;
}
.page-nav-view .back[data-v-28088d38],
.page-nav-view .right-view[data-v-28088d38] {
  display: inline-flex;
  width: 44px;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.page-nav-white-view[data-v-28088d38] {
  background-color: #FFFFFF;
}
.page-nav-trans-view[data-v-28088d38] {
  background-color: transparent;
}
.page-nav-white-icon[data-v-28088d38] svg path {
  fill: #FFFFFF;
}
