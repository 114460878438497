.main-layout-view[data-v-316e8ce1] {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
.main-layout-view[data-v-316e8ce1] {
    width: 768px;
    margin: 0 auto;
}
}
