.giftcard-accept[data-v-2faf84d0] {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
}
.giftcard-accept .title-text[data-v-2faf84d0] {
  margin-bottom: 24px;
  text-align: center;
}
.giftcard-accept .card[data-v-2faf84d0] {
  align-self: center;
}
.giftcard-accept .sender-text[data-v-2faf84d0] {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 20px;
}
.giftcard-accept .close-btn[data-v-2faf84d0] {
  margin-top: 24px;
}
.giftcard-accept .btn-view[data-v-2faf84d0] {
  margin-top: 24px;
  display: flex;
}
.giftcard-accept .btn-view .refuse-btn[data-v-2faf84d0] {
  margin-right: 10px;
}
