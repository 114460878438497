.ant-btn.custom-btn-color-00AAEF[data-v-1e74da12],
.ant-btn.custom-btn-color-00AAEF[data-v-1e74da12]:hover,
.ant-btn.custom-btn-color-00AAEF[data-v-1e74da12]:focus,
.ant-btn.custom-btn-color-00AAEF[data-v-1e74da12]:active {
  background-color: #00AAEF;
  color: #FFFFFF;
}
.ant-btn.custom-btn-color-000000[data-v-1e74da12],
.ant-btn.custom-btn-color-000000[data-v-1e74da12]:hover,
.ant-btn.custom-btn-color-000000[data-v-1e74da12]:focus,
.ant-btn.custom-btn-color-000000[data-v-1e74da12]:active {
  background-color: #000000;
  color: #FFFFFF;
}
