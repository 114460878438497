.ant-modal.common-modal-view {
  padding: 0;
}
.ant-modal.common-modal-view .ant-modal-content {
  border-radius: 12px;
}
.ant-modal.common-modal-view .ant-modal-content .ant-modal-close-x {
  line-height: 70px;
}
.ant-modal.common-modal-view .ant-modal-content .ant-modal-header {
  border-radius: 12px 12px 0 0;
  border: none;
  padding: 24px 0 0;
}
.ant-modal.common-modal-view .ant-modal-content .ant-modal-header .ant-modal-title {
  text-align: center;
}
.ant-modal.common-modal-view .ant-modal-content .ant-modal-body {
  padding: 24px 20px 0;
}
.ant-modal.common-modal-view .ant-modal-content .ant-modal-footer {
  border-radius: 0 0 12px 12px;
  border: none;
  padding: 12px 20px 24px;
}
.ant-modal.common-modal-view .ant-modal-content .ant-modal-footer .footer-view {
  display: flex;
  justify-content: center;
}
.ant-modal.common-modal-view .ant-modal-content .ant-modal-footer .footer-view-left-right {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.ant-modal.common-modal-view .ant-modal-content .ant-modal-footer .footer-view-left-right > button {
  width: calc(50% - 5px);
}
