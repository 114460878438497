.background-FFF[data-v-5a78926a] {
  background-color: #FFFFFF;
}
.background-EFEFEF[data-v-5a78926a] {
  background-color: #EFEFEF;
}
.background-333[data-v-5a78926a] {
  background-color: #333333;
}
.page-layout-view[data-v-5a78926a] {
  height: 100%;
}
.page-layout-view .page-header[data-v-5a78926a] {
  position: fixed;
  top: 0;
  z-index: 10;
}
.page-layout-view .page-body[data-v-5a78926a] {
  display: flex;
  flex-direction: column;
}
.page-layout-view .page-body .body-content[data-v-5a78926a] {
  display: flex;
  flex-grow: 1;
}
.page-layout-view .page-body .body-content .main-content[data-v-5a78926a] {
  width: 100%;
}
.page-layout-view .page-footer[data-v-5a78926a] {
  position: fixed;
  bottom: 0;
}
.page-layout-view .page-footer .page-indicator[data-v-5a78926a] {
  height: 24px;
}
